/*======================
    Axil Button Styles 
========================*/

.axil-button-group {
  margin: -10px;
  a,
  button {
    &.axil-button {
      margin: 10px;
    }
  }
}

a,
button {
  &.axil-button {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    color: $darkest-color;
    display: inline-block;
    height: 46px;
    line-height: 46px;
    text-decoration: none;
    padding: 0 25px;
    z-index: 2;
    font-size: 16px;
    @extend %fontWeight700;
    border: 0 none;
    background: #ffffff;
    @media #{$sm-layout} {
      font-size: 14px;
    }

    &.axil-link-button {
      color: $heading-color;
      height: auto;
      line-height: initial;
      position: relative;
      padding: 0;
      font-weight: 700;
      text-shadow: none;
      background: transparent;

      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        content: "";
        @extend %transition;
      }

      &:hover {
        color: $primary-color;
        &::after {
          background: $primary-color;
          width: 100%;
        }
      }
    }
    span {
      display: inline-block;
      &.button-text {
        padding-right: 25px;
        @media #{$sm-layout} {
          padding-right: 12px;
        }
      }
      &.button-icon {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 0 4.5px 6px;
        border-color: transparent transparent transparent #000024;
      }
    }

    &.btn-transparent {
      background-color: transparent;
      span {
        &.button-text {
          padding-right: 25px;
        }

        &.button-icon {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4.5px 0 4.5px 6px;
          border-color: transparent transparent transparent #000024;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border: 2px solid $darkest-color;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        background-color: $darkest-color;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
      }

      &:hover {
        color: $white-color;

        span {
          &.button-icon {
            border-color: transparent transparent transparent #ffffff;
          }
        }

        &::before {
          opacity: 0;
          -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
        }

        &::after {
          opacity: 1;
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }
      }
    }

    &.btn-large {
      height: 56px;
      line-height: 56px;
      @media #{$sm-layout} {
        height: 52px;
        line-height: 52px;
      }
    }

    &.btn-extra-large {
      height: 56px;
      line-height: 56px;
      padding: 0 92px;
      @media #{$sm-layout} {
        padding: 0 20px;
        height: 52px;
        line-height: 52px;
      }
    }

    &.btn-xxl {
      padding: 0 92px;
      @media #{$sm-layout} {
        padding: 0 24px;
      }
    }

    &.btn-small {
      height: 36px;
      line-height: 36px;
    }

    &.btn-solid {
      color: $white-color;
      span {
        &.button-icon {
          border-color: transparent transparent transparent #ffffff;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $darkest-color;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        border: 2px solid $darkest-color;
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
      }
      &:hover {
        color: $darkest-color;
        background: transparent;
        span {
          &.button-icon {
            border-color: transparent transparent transparent $darkest-color;
          }
        }
        &::before {
          opacity: 0;
          -webkit-transform: scale(0.5, 0.5);
          transform: scale(0.5, 0.5);
        }
        &::after {
          opacity: 1;
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }
      }

      &.bgextra07-color {
        &::before {
          background-color: $extra07-color;
        }
        &::after {
          border: 2px solid $extra07-color;
        }
      }

      &.btn-extra02-color {
        &::before {
          background-color: $extra02-color;
        }
        &::after {
          border: 2px solid $extra02-color;
        }
        &:hover {
          color: $extra02-color !important;
          span {
            &.button-icon {
              border-color: transparent transparent transparent $extra02-color;
            }
          }
        }
      }

      &.btn-extra07-color {
        &::before {
          background-color: $extra07-color;
        }
        &::after {
          border: 2px solid $extra07-color;
        }
        &:hover {
          color: $extra07-color !important;
          span {
            &.button-icon {
              border-color: transparent transparent transparent $extra07-color;
            }
          }
        }
      }

      &.btn-primary-color {
        &::before {
          background-color: $primary-color;
        }
        &::after {
          border: 2px solid $primary-color;
        }
        &:hover {
          color: $primary-color !important;
          span {
            &.button-icon {
              border-color: transparent transparent transparent $primary-color;
            }
          }
        }
      }
    }
  }
}

/* ========================
    Slider Video Play 
=========================*/

.yu2fvl {
  z-index: 9999;
  top: 0;
}

.yu2fvl-iframe {
  display: block;
  height: 100%;
  width: 100%;
  border: 0;
}

.yu2fvl-overlay {
  z-index: 9998;
  background: #000;
  opacity: 0.8;
}

.yu2fvl-close {
  position: absolute;
  top: 1px;
  left: 100%;
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background: #f10 !important;
  background-color: #f10 !important;
  color: #ffffff;
  border: 0;
  background: none;
  cursor: pointer;
}
@media #{$md-layout} {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
  }
}

@media #{$sm-layout} {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
  }
  .video {
    h2 {
      color: #ffffff;
      font-size: 32px;
    }

    h4 {
      font-size: 22px;
      line-height: 32px;
    }
  }
}

a {
  &.axil-link {
    position: relative;
    display: inline-block;
    z-index: 1;
    &::after {
      content: "";
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      z-index: -1;
      -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor;
    }
    &:hover {
      &::after {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }
}
