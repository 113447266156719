/*--------------------------------
    Axil Testimonial Styles 
----------------------------------*/
.axil-testimonial {
  position: relative;
  z-index: 2;
  @extend %transition;
  &::before {
    position: absolute;
    content: "";
    right: 40px;
    color: #00d09c;
    font-size: 45px;
    top: 69px;
    font-weight: 600;
    z-index: 3;
    background-image: url("../../../images/shape/quote.svg");
    background-repeat: no-repeat, repeat;
    width: 52px;
    height: 44px;
    @media #{$md-layout} {
      right: 20px;
    }
    @media #{$lg-layout} {
      right: 20px;
    }
  }
  .inner {
    position: relative;
    padding: 60px 36px;
    z-index: 2;
    @media #{$sm-layout} {
      padding: 22px 19px;
    }
    @media #{$md-layout} {
      padding: 30px 40px;
    }
    &::before {
      width: 100%;
      height: 92%;
      left: -25px;
      bottom: -25px;
      background: rgba(0, 2, 72, 0.1);
      opacity: 0;
      content: "";
      position: absolute;
      z-index: -1;
      transform-style: preserve-3d;
      transform: rotateY(-2deg);
      filter: blur(40px);
      transition: 0.3s;
      @media #{$sm-layout} {
        display: none;
      }
    }
    &::after {
      background: #ffffff;
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      z-index: -1;
      transform: perspective(700px) rotateY(-2deg);
      opacity: 0;
      @extend %transition;
      width: 100%;
      box-shadow: 0 0 1px transparent;
    }
  }
  .clint-info-wrapper {
    display: flex;
    @media #{$sm-layout} {
      display: block;
    }
    .thumb {
      position: relative;
      height: 100%;
      z-index: 1;
      display: inline-block;
      &::before {
        width: 92%;
        height: 92%;
        left: -10px;
        bottom: -10px;
        background: rgba(0, 2, 72, 0.3);
        content: "";
        position: absolute;
        filter: blur(14px);
        transition: 0.3s;
        z-index: -1;
        @media #{$sm-layout} {
          display: none;
        }
      }
      img {
        height: 60px;
      }
    }
    .client-info {
      padding-left: 25px;
      @media #{$sm-layout} {
        padding-left: 0;
        margin-top: 15px;
      }
      h4 {
        &.title {
          line-height: 32px;
          margin-bottom: 5px;
          @media #{$sm-layout} {
            line-height: 24px;
            font-size: 20px;
          }
        }
      }

      span {
        line-height: 28px;
        display: inline-block;
      }
    }
  }
  .description {
    margin-top: 38px;
    @media #{$md-layout} {
      margin-top: 20px;
    }
    @media #{$lg-layout} {
      margin-top: 20px;
    }
    @media #{$sm-layout} {
      margin-top: 16px;
    }
    p {
      margin-bottom: 0;
    }
    a {
      &.axil-link-button {
        margin-top: 40px;
        color: $heading-color;
        @extend %transition;
        text-decoration: none;
        position: relative;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        &::after {
          position: absolute;
          bottom: 0;
          width: 0;
          height: 1px;
          content: "";
          background: currentColor;
          left: 0;
          @extend %transition;
        }
        @media #{$sm-layout} {
          margin-top: 15px;
        }
        @media #{$md-layout} {
          margin-top: 20px;
        }
        @media #{$lg-layout} {
          margin-top: 20px;
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  &.style-var--2 {
    .clint-info-wrapper {
      .thumb {
        position: relative;
        height: 100%;
        z-index: 1;
        display: inline-block;
        &::before {
          width: 92%;
          height: 92%;
          left: -10px;
          bottom: -10px;
          background: rgba(0, 2, 72, 0.3);
          content: "";
          position: absolute;
          filter: blur(14px);
          transition: 0.3s;
          z-index: -1;
          @media #{$sm-layout} {
            display: none;
          }
        }
        img {
          height: 60px;
          width: 60px;
          border-radius: 100%;
          border: 4px solid #ffffff;
          box-sizing: border-box;
        }
      }
    }
  }

  &.active {
    .inner {
      &::before {
        opacity: 1;
      }
      &::after {
        opacity: 1;
      }
    }
    .description {
      a {
        &.axil-link-button {
          color: $primary-color;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
