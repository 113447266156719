/*------------------------
    Brand Styles 
-------------------------*/

.axil-brand-logo-wrapper {
  padding-left: 88px;
  padding-right: 86px;
  @media #{$sm-layout} {
    padding-left: 0;
    padding-right: 0;
  }
  @media #{$md-layout} {
    padding-left: 0;
    padding-right: 0;
  }
}

.brand-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background: #c7c7d6;
    width: 100%;
    height: 1px;
    top: 50%;
    @media #{$large-mobile} {
      display: none;
    }
  }

  li {
    flex-basis: 33.33%;
    @media #{$large-mobile} {
      flex-basis: 50%;
    }

    a {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 200px;
      text-align: center;
      border-right: 1px solid #c7c7d6;
      @extend %transition;

      @media #{$large-mobile} {
        border: 0 none;
        height: 100px;
        line-height: 100px;
      }

      > span {
        overflow: visible !important;
      }

      img {
        @extend %transition;
      }

      &:hover {
        background: #f6f6f6;
        border-color: #f6f6f6;

        img {
          transform: scale(1.2);
        }
      }
    }

    &:nth-child(3) {
      a {
        border-color: transparent;
      }
    }

    &:last-child {
      a {
        border-color: transparent;
      }
    }
  }
}

.bg-color-white {
  .brand-list {
    &::before {
      background: #f6f5fa;
      @media #{$large-mobile} {
        display: none;
      }
    }

    li {
      a {
        border-right: 1px solid #f6f5fa;

        > span {
          overflow: visible !important;
        }

        @media #{$large-mobile} {
          border: 0 none;
        }
      }

      &:nth-child(3) {
        a {
          border-color: transparent;
        }
      }

      &:last-child {
        a {
          border-color: transparent;
        }
      }
    }
  }
}

.client-list.slick-initialized .slick-slide {
  margin-bottom: 0;
}

/*-------------------------
Client List  
--------------------------*/
ul {
  &.client-list {
    position: relative;
    z-index: 2;

    .slick-slide {
      margin-bottom: 0;
      text-align: center;

      a {
        display: block;

        > span {
          display: block !important;
        }

        img {
          width: auto !important;
          min-width: initial !important;
          max-height: 40px !important;

          @media #{$sm-layout} {
            width: auto;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.axil-bg-oval {
  position: relative;
  z-index: 2;
  padding-top: 110px;
  margin-top: -110px;
  background-color: transparent;

  .bg-shape-container {
    .light,
    .dark {
      > span {
        z-index: -1;
      }
    }

    .dark {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .bg-shape-container {
      .light,
      .dark {
        > span {
          width: 150vw !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding-top: 70px;
    margin-top: -150px;
    background-size: contain;
  }
}
