.my_switcher {
  position: fixed;
  left: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  z-index: 99;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    li {
      button {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        border: none;
        transition: 0.4s;

        span {
          display: block;
          text-transform: uppercase;

          &.title {
            padding-left: 10px;
            transform: rotate(-180deg);
          }
        }

        &.active {
          display: none;
        }

        &.light {
          color: #ffffff;
        }

        &.dark {
          color: #18191a;
        }
      }
    }
  }
}
