/*=====================
 * Section Title
========================*/

.section-title {
    span {
        &.sub-title {
            height: 36px;
            padding: 0 12px;
            font-size: 15px;
            line-height: 35px;
            display: inline-block;
            margin-bottom: 20px;
            @media #{$sm-layout} {
                margin-bottom: 10px;
            }
            &.extra08-color {
                background: rgba(0, 208, 156, 0.1);
            }

            &.extra04-color {
                background: rgba(0, 207, 255, 0.1);
            }

            &.extra05-color {
                background: rgba(122, 25, 255, 0.1)
            }

            &.extra07-color {
                background: rgba(0, 122, 255, 0.1)
            }

            &.extra06-color {
                background: rgba(255,138,91,0.1)
            }

            &.extra10-color {
                background: rgba(0,122,255,0.1);
            }


        }
    }
    h2 {
        &.title {
            margin-bottom: 20px;
            @media #{$lg-layout} {
                margin-bottom: 15px;
                font-size: 40px;
                line-height: 52px;
            }
            @media #{$md-layout} {
                margin-bottom: 15px;
                font-size: 35px;
                line-height: 42px;
                br {
                    display: none;
                }
            }
            @media #{$sm-layout} {
                margin-bottom: 10px;
                br {
                    display: none;
                }
            }
        
        }
    }


    &.text-center{
        p {
            padding: 0 25%;
            @media #{$lg-layout} {
                padding: 0 20%;
            }
            @media #{$md-layout} {
                padding: 0 10%;
            }

            @media #{$sm-layout} {
                padding: 0;
            }
        }
    }

    &.text-start {
        p {
            padding: 0 25% 0 0;
            @media #{$sm-layout} {
                padding: 0;
            }
            @media #{$md-layout} {
                padding: 0;
            }
        }
    }

    &.text-end {
        p {
            padding: 0 0 0 25%;
            @media #{$sm-layout} {
                padding: 0;
            }
        }
    }
}
