/*----------------------
    Animation Css  
-----------------------*/
@keyframes customOne {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes customTwo {
  0% {
    transform: (translate(0px, 0px));
  }

  50% {
    transform: (translate(100px, 0px));
  }

  100% {
    transform: (translate(50px, 50px));
  }
}

.customOne {
  animation: customOne 2s infinite;
}

/*------------------------
    Move Up Animation  
---------------------------*/
.move-up {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);

  &.animate {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-animation: moveVertical 0.65s ease forwards;
    animation: moveVertical 0.65s ease forwards;
  }
}

/* ------------------------
    Custom Animation 01 
----------------------------*/
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }

  to {
    margin-top: 0;
  }
}

/*------------------------
	slidefadeinup
--------------------------*/
@-webkit-keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.slideFadeInUp {
  -webkit-animation-name: slideFadeInUp;
  animation-name: slideFadeInUp;
}

/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/
@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/*----------------------
    Animated Text  
-----------------------*/
@keyframes animateBorder {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  51% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

.box span.animating-line {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 10;
  box-sizing: border-box;
}

.box span.animating-line:nth-child(1) {
  transform: rotate(0deg);
}

.box span.animating-line:nth-child(2) {
  transform: rotate(90deg);
  left: auto;
  right: 0;
}

.box span.animating-line:nth-child(3) {
  transform: rotate(180deg);
}

.box span.animating-line:nth-child(4) {
  transform: rotate(270deg);
}

.box span.animating-line::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #ffffff;
  animation: animateBorder 4s linear infinite;
}

.box span.animating-line:nth-child(1)::before {
  left: 0;
}

.box span.animating-line:nth-child(2)::before {
  top: -122px;
  right: 0;
}

.box span.animating-line:nth-child(4)::before {
  top: -121px;
  left: 0;
}

.box span.animating-line:nth-child(3)::before {
  right: 0;
  left: auto;
}

/*--------------------------
Scroll Line Animation  
----------------------------*/
@keyframes line1 {
  0% {
    opacity: 1;
    bottom: 0;
  }
  30% {
    bottom: 30%;
  }
  50% {
    bottom: 50%;
  }
  70% {
    bottom: 70%;
  }
  100% {
    bottom: 100%;
    opacity: 0.5;
  }
}

@keyframes line5 {
  0% {
    opacity: 1;
    bottom: 400px;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 100%;
    opacity: 0.5;
  }
}

@keyframes line2 {
  0% {
    opacity: 1;
    top: 0;
  }
  30% {
    bottom: 30%;
  }

  50% {
    bottom: 50%;
  }

  70% {
    bottom: 70%;
  }

  100% {
    top: 100%;
    opacity: 0.5;
  }
}

@keyframes line3 {
  0% {
    opacity: 1;
    top: 500px;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 100%;
    opacity: 0.5;
  }
}

@keyframes line4 {
  0% {
    opacity: 1;
    top: 300px;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 100%;
    opacity: 0.5;
  }
}

/*--------------------------------
Scroll Down Button Animation  
----------------------------------*/
@keyframes scrollDown {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

/*======================
    Custom Animation 
========================*/
.link-hover {
  a {
    display: inline-block;
    padding: 3px 0;
    position: relative;
    @extend %transition;
    overflow: hidden;
    -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    z-index: 1;

    &::after {
      content: "";
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      z-index: -1;
      -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor;
    }

    &:hover {
      &::after {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }

  &.color-var--2 {
    a {
      &:hover {
        color: #5bcc9f !important;
      }
    }
  }
}

/* SPLITTING */
.split-word,
.split-letter {
  display: inline-block;
}

.word-break {
  display: flex;
  white-space: pre-line;
}

.split-letter {
  position: relative;
}

.split-letter {
  display: inline-block;
  animation: texteffect 0.6s cubic-bezier(0.5, 0, 0.5, 1) both;
  animation-delay: calc(10ms * var(--char-index));
}

/* ANIMATIONS */
@keyframes texteffect {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes texteffect {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* REVEAL EFFECT */
.reveal-effect {
  position: relative;
}

.reveal-effect > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
}

.reveal-effect > * {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.reveal-effect {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.reveal-effect {
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-effect.animated {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.reveal-effect.animated * {
  -webkit-animation-name: show-img-1;
  animation-name: show-img-1;
}

.reveal-effect::after {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.reveal-effect::after {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0.05, 0);
  transform: scale(0.05, 0);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect.masker::after {
  background-color: rgba(0, 2, 72, 0.3);
}

.reveal-effect.animated::after {
  -webkit-animation-name: slide-bg-2;
  animation-name: slide-bg-2;
}

@-webkit-keyframes show-img-1 {
  0%,
  66.6% {
    visibility: hidden;
  }

  100%,
  66.7% {
    visibility: visible;
  }
}

@keyframes show-img-1 {
  0%,
  66.6% {
    visibility: hidden;
  }

  100%,
  66.7% {
    visibility: visible;
  }
}

@-webkit-keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    transform: scale(0.05, 1);
  }

  66.6% {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  66.7% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}

@keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    transform: scale(0.05, 1);
  }

  66.6% {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  66.7% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}

// Scroll Animation
[data-aos="aos-fade-in-up"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0);

    &.aos-animate {
      -webkit-transform: none;
      transform: none;
    }
  }
}
