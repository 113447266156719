/* ---------------------------
    Single Product Styles  
-------------------------------*/
.axil-working-process {
  display: flex;
  align-items: center;

  @media #{$md-layout} {
    flex-wrap: wrap;
  }

  @media #{$sm-layout} {
    flex-wrap: wrap;
  }

  .thumbnail {
    flex-shrink: 0;

    @media #{$md-layout} {
      flex-basis: 100%;
    }

    @media #{$sm-layout} {
      flex-basis: 100%;
    }

    .image {
      img {
        -webkit-transform: perspective(500px) rotateY(-5deg);
        transform: perspective(500px) rotateY(-5deg);
        @media #{$sm-layout} {
          width: 100%;
          transform: inherit;
        }
      }
    }
  }

  .content {
    padding-left: 110px;
    position: relative;
    margin-right: 200px;

    @media #{$lg-layout} {
      padding-left: 40px;
      margin-right: 40px;
    }

    @media #{$md-layout} {
      padding-left: 40px;
      margin-right: 40px;
      margin-top: 40px;
      flex-basis: 100%;
    }

    @media #{$sm-layout} {
      padding-left: 0;
      margin-right: 40px;
      flex-basis: 100%;
      margin-top: 30px;
    }

    @media #{$large-mobile} {
      margin-right: 0;
      margin-top: 30px;
    }

    .inner {
      .section-title {
        span {
          &.process-step-number {
            width: 149px;
            height: 149px;
            position: absolute;
            top: -58px;
            right: 0;
            display: inline-block;
            color: #737387;
            line-height: 149px;
            opacity: 0.2;
            font-size: 60px;
            border-radius: 100%;
            text-align: center;
            border: 1px solid #979797;
            box-sizing: border-box;

            @media #{$md-layout} {
              height: 100px;
              width: 100px;
              line-height: 100px;
              font-size: 49px;
            }

            @media #{$sm-layout} {
              height: 100px;
              width: 100px;
              line-height: 100px;
              font-size: 49px;
              top: -16px;
            }

            @media #{$large-mobile} {
              top: -6px;
              height: 70px;
              width: 70px;
              line-height: 70px;
              font-size: 36px;
            }
          }
        }
      }
    }
  }

  &.text-start {
    .content {
      margin-right: 110px;
      padding-left: 200px;

      @media #{$lg-layout} {
        padding-left: 40px;
        margin-right: 40px;
      }
      @media #{$md-layout} {
        padding-left: 40px;
        margin-right: 40px;
      }
      @media #{$sm-layout} {
        padding-left: 0;
        margin-right: 40px;
        margin-top: 30px;
      }
      @media #{$large-mobile} {
        padding-left: 0;
        margin-right: 0;
        margin-top: 30px;
      }
    }
  }
}
